import { useState } from "react";
import { Registration } from "../../models/registration";
import CamelotCheckbox from "../CameloCheckbox";
import CamelotButton from "../CamelotButton";
import CamelotInput from "../CamelotInput";
import styles from "./register.module.scss";
import { cfIsValid } from "../../libs/utils/regex_CF";
import { emailIsValid } from "../../libs/utils/regex_email";

export default function RegisterFormView(props: {
  onSubmit?: (e: Registration) => unknown;
  onExecute: boolean;
}) {
  const [registration, setRegistration] = useState<Registration>({
    email: "",
    cellulare: "",
    cod_fiscale: "",
    identificativoFrazioneVoto: undefined
  });

  const [disabled, setDisabled] = useState(true);
  const [correctEmail, setCorrectEmail] = useState("");

  return (
    <div className={styles.mainForm}>
      
      <div className={styles.inputs}>
        <CamelotInput
          label="Email *"
          onChange={(e) => setCorrectEmail(e.target.value)}
        />
        <CamelotInput
          label="Conferma email *"
          onChange={(e) => {
            
              setRegistration({ ...registration, email: e.target.value });
          }}
          preventCopyPaste={true}
        />
        <CamelotInput
          label="Cellulare *"
          onChange={(e) =>
            setRegistration({ ...registration, cellulare: e.target.value })
          }
          phoneNumber
        />
        <CamelotInput
          label="Codice fiscale *"
          onChange={(e) =>
            setRegistration({
              ...registration,
              cod_fiscale: e.target.value.toUpperCase(),
            })
          }
        />
      </div>
      <div className={styles.privacy}>
        <CamelotCheckbox action={() => setDisabled(!disabled)} />
        <hr
          style={{
            backgroundColor: "#0069CA",
            border: "none",
            height: "1px",
          }}
        ></hr>
        <p className={styles.warning}>
          Dichiaro che i dati inseriti all'atto della registrazione sono
          autentici, completi e corrispondono all'utente che ha eseguito la
          registrazione stessa, consapevole che il conferimento di dati non
          conformi al vero o non appartenenti all'utente o l'effettuazione di
          plurime iscrizioni verranno considerati alla stregua del rilascio di
          false dichiarazioni in scrittura privata, dando luogo alle relative
          responsabilità anche di natura penali.
        </p>
        <p style={{ color: "#8f8f8f" }}>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </p>
      </div>
      {cfIsValid(registration.cod_fiscale) ||
      registration.cod_fiscale === "" ? null : (
        <p style={{ margin: "0", color: "#990000" }}>
          Il codice fiscale inserito non è valido
        </p>
      )}
      {emailIsValid(registration.email) || registration.email === "" ? null : (
        <p style={{ margin: "0", color: "#990000" }}>
          L'email inserita non è valida
        </p>
      )}
      {correctEmail !== "" && registration.email !== "" && correctEmail !== registration.email ? (
        <p style={{ margin: "0", color: "#990000" }}>Il campo Conferma email non corrisponde al campo Email</p>
      ) : null}
      <div className={styles.confirm}>
        <CamelotButton
          disabled={
            disabled ||
            cfIsValid(registration.cod_fiscale) === false ||
            emailIsValid(registration.email) === false ||
            registration.cellulare === "" ||
            correctEmail !== registration.email ||
            props.onExecute
          }
          green={props.onExecute ? true : false}
          text={props.onExecute ? "Dati confermati" : "Conferma i  dati"}
          action={async (e) => props.onSubmit && props.onSubmit(registration)}
        />
      </div>
    </div>
  );
}
