import { ClipboardEvent, useState } from "react";
import styles from "./input.module.scss";

export default function CamelotInput(props: {
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  phoneNumber?: boolean;
  type?: string;
  name?: string;
  autocomplete?: string;
  preventCopyPaste?: boolean
}) {
  const [errormessage, setErrorMessage] = useState(false);

  const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    setErrorMessage(true);

    setTimeout(() => {
      setErrorMessage(false);
    }, 10000);
  };

  return (
    <div className={styles.main}>
      <label>{props.label}</label>
      <div className={styles.inputs}>
        {props.phoneNumber ? (
          <input disabled className={styles.prefisso} value={"+39"} />
        ) : null}
        <div className={styles.wrapper}>
          <input
          style={props.phoneNumber ? {width:"calc(100% - 30px)"} : {width: "100%"}}
            type={props.type}
            name={props.name}
            onChange={props.onChange}
            onPaste={(e) =>{props.preventCopyPaste ?  preventCopyPaste(e) : console.log("")}}
          ></input>
          <p
            className={
              errormessage ? styles.errorMessage : styles.noErrorMessage
            }
          >
            Non è possibile incollare il testo
          </p>
        </div>
      </div>
    </div>
  );
}
