import styles from "./header.module.scss";
import camelotLogo from "../../libs/assets/camelotLogo.svg";
import comuneLogo from "../../libs/assets/comune_logo.png";
import quartieriLogo from "../../libs/assets/quartieri_logo.png";

const Header = () => {
  return (
    <div className={styles.main}>
      <div className={styles._camelotLogo}>
        <img src={camelotLogo} alt={"logo"} />
      </div>
      <div className={styles._title}>
        <div className={styles.__firstWrap}>
          <img src={comuneLogo} alt={"comunediVimercate"} />
          <div className={styles.__titleText}>
            <p>
            ELEZIONE PRESIDENTE DELLA CONSULTA DI QUARTIERE DI ORENO DEL COMUNE DI VIMERCATE 
            </p>
            <p>15-24 MARZO 2024</p>
          </div>
        </div>

        <div className={styles.right}>
          <img src={quartieriLogo} alt={"quartieriLogo"} />
          <div className={styles.__tag}>
            <p style={{ margin: "0" }}>REGISTRAZIONE AL VOTO</p>
          </div>
        </div>
      </div>
      
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img className={styles.logoQuartieriMobile} src={quartieriLogo} alt={"quartieriLogo"} />
      </div>

      <div className={styles._description}>
      Dalle ore 15 del 15 marzo alle ore 15 del 24 marzo 2024 i cittadini del quartiere di Oreno del Comune di Vimercate saranno chiamati ad eleggere il Presidente della Consulta del Quartiere di Oreno. <br />
      Ogni cittadino del quartiere di Oreno potrà votare per eleggere il presidente all'interno del suo quartiere.
      </div>
    </div>
  );
};

export default Header;
