import { useState } from "react";
import styles from "./App.module.scss";
import { useNavigate } from "react-router";
import { Response, View } from "./models/step_enum";
import { Registration } from "./models/registration";
import { registrationPOST } from "./services/registration_post";
import { otpPOST } from "./services/otp_post";
import Header from "./components/Header";
import RegisterFormView from "./components/RegisterFormView";
import InsertOtpCodeView from "./components/InsertOtpCodeView";
import ResponseView from "./components/ResponseView";
import { resendOTP } from "./services/resend_otp";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function App() {
  const [currentView, setCurrentView] = useState(View.register);
  const [registrationID, setRegistrationID] = useState<string>();
  const [errorOtpMessage, setErrorOtpMessage] = useState<string>();
  const [onExecute, setOnExecute] = useState<boolean>();

  const { executeRecaptcha } = useGoogleReCaptcha();

  async function sendRegistration(registration: Registration) {
    const token = await executeRecaptcha!("submit");
    console.log(token);
    setOnExecute(true);
    try {
      const resp = await registrationPOST({ ...registration, token: token });
      if (
        resp?.statusCode === 401 &&
        resp.responseBody ===
          "Non autorizzato, Codice fiscale non presente in lista"
      ) {
        setCurrentView(View.responseFailedCF);
        setOnExecute(false);
      } else if (
        resp?.statusCode === 400 ||
        (resp?.statusCode === 401 &&
          resp.responseBody === "Non autorizzato, utente già registrato")
      ) {
        setCurrentView(View.responseFailedGenerale);
        setOnExecute(false);
      } else {
        setRegistrationID(resp?.responseBody);
        setCurrentView(View.otpcode);
        setOnExecute(false);

        console.log("qui" + registrationID);
      }
    } catch (error) {
      console.log(error);
      setOnExecute(false);
    }
  }

  async function sendOTPCode(registrationID: string, otp: string) {
    try {
      const resp = await otpPOST(registrationID, otp);
      setErrorOtpMessage(resp != undefined ? resp.responseBody : "");
      if (resp?.statusCode === 400) {
        setCurrentView(View.responseFailedOtp);
      } else {
        setCurrentView(View.responseSuccess);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function resendOTPCode(registrationID: string) {
    try {
      const resp = await resendOTP(registrationID);
      setErrorOtpMessage(resp !== undefined ? resp.responseBody : "");
      if (resp?.statusCode === 400) {
      } else {
        setCurrentView(View.otpcode);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.main}>
      <div className={styles._header}>
        <Header />
      </div>

      <div className={styles._body}>
        {(currentView === View.register || currentView === View.otpcode) && (
          <p style={{ margin: "0", textAlign: "center" }}>
            COMPLETA LA PROCEDURA DI IDENTIFICAZIONE PER PARTECIPARE ALLA
            VOTAZIONE
          </p>
        )}
        <p className={styles.__description}>
          Dalle ore 15 del 15 marzo alle ore 15 del 24 marzo 2024 i cittadini
          del quartiere di Oreno del Comune di Vimercate saranno chiamati ad
          eleggere il Presidente della Consulta del Quartiere di Oreno. <br />
          Ogni cittadino del quartiere di Oreno potrà votare per eleggere il
          presidente all'interno del suo quartiere.
        </p>
        {currentView === View.register ? (
          <RegisterFormView
            onSubmit={(e) => {
              sendRegistration(e);
            }}
            onExecute={onExecute != null ? onExecute : false}
          />
        ) : currentView === View.otpcode ? (
          <InsertOtpCodeView
            onSubmit={async (e) => {
              await sendOTPCode(registrationID!, e);
            }}
            editPhone={() => setCurrentView(View.register)}
            resendOtp={() => {
              resendOTPCode(registrationID!);
            }}
          />
        ) : currentView === View.responseFailedGenerale ? (
          <ResponseView
            response={Response.failedGenerale}
            errorMessage={
              "La procedura di identificazione non è stata completata correttamente. Assicurati che tu non abbia già effettuato la registrazione con questa e-mail o questo numero di telefono."
            }
            toForm={() => {
              setCurrentView(View.register);
            }}
          />
        ) : currentView === View.responseFailedCF ? (
          <ResponseView
            response={Response.failedGenerale}
            errorMessage={"Codice fiscale non idoneo"}
            toForm={() => {
              setCurrentView(View.register);
            }}
          />
        ) : currentView === View.responseFailedOtp ? (
          <ResponseView
            response={Response.responseFailedOtp}
            errorMessage={errorOtpMessage}
            toForm={() => {
              setCurrentView(View.register);
            }}
            toOtpView={() => {
              setCurrentView(View.otpcode);
            }}
            resendOtp={() => {
              resendOTPCode(registrationID!);
            }}
          />
        ) : currentView === View.responseSuccess ? (
          <ResponseView response={Response.success} />
        ) : null}
        {currentView === View.register && (
          <div>
            <iframe
              className={styles.video}
              src="https://www.youtube.com/embed/DNLJllbTyIY?si=nTJC1u6lSb2D_Mw1"
              title="Come votare con Camelot"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        )}
        <p style={{ margin: "0", color: "#8F8F8F", textAlign: "center" }}>
          Se hai bisogno di supporto contattaci a{" "}
          <a
            href="mailto:support@camelot.vote"
            style={{
              textDecoration: "none",
              color: "#0069CA",
              fontWeight: 600,
            }}
          >
            support@camelot.vote{" "}
          </a>
        </p>
      </div>
    </div>
  );
}
